// @flow
import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import { atom, useSetRecoilState, useRecoilValue, selector } from 'recoil';
import type { RecoilState } from 'recoil';
import { getPageType, PAGE_TYPES } from 'utils/pageTypes';
import { extractWorklistIds } from 'hooks/useWorklistId';
import memoize from 'lodash.memoize';
import { uniquePageId as id } from 'modules/activeWindow';
import { PATH } from '../config/constants';
import { getPathName } from '../utils/pageTypes';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';

const POLL_INTERVAL = 30 * 1000;
const DUPLICATE_NAVIGATION_PAGE = PATH.WORKLIST;

export const getWindowId = (urlOrPathname: string): ?string => {
  const pathname = getPathName(urlOrPathname);
  const viewerMatch = matchPath(PATH.VIEWER, pathname);
  return viewerMatch?.params?.windowId;
};

export type PageType = {
  type: $Values<typeof PAGE_TYPES>,
  id: string,
  createTimestamp: number,
  timestamp: number,
  windowId: ?string,
  url: string,
  worklistIds: Array<string>,
};
// Constant time for when the page was originally created, even when
// refreshing the timestamp to maintain active tab states it will have a
// property to allow sorting by when they were created.
const creationTimestamp = Date.now();
const UNIQUE_PAGES = [PAGE_TYPES.VIEWER];

export const getPage = (): PageType => ({
  type: getPageType(window.location.href),
  id,
  createTimestamp: creationTimestamp,
  timestamp: Date.now(),
  windowId: getWindowId(window.location.href),
  url: window.location.href,
  worklistIds: extractWorklistIds(window.location.pathname),
});

export type Message = { type: 'register' | 'unregister', payload: PageType } | { type: 'ping' };
export const bc: BroadcastChannel<Message> = new BroadcastChannel<Message>('use-tab-list');

const register = () => bc.postMessage({ type: 'register', payload: getPage() });
register();

const ping = () => bc.postMessage({ type: 'ping' });

window.addEventListener('beforeunload', () => {
  bc.postMessage({ type: 'unregister', payload: getPage() });
});

export const openTabsState: RecoilState<PageType[]> = atom({
  key: 'use-open-tabs',
  default: [getPage()],
});

const memoizedStableTabs = memoize(
  (tabs) => {
    return tabs.map(({ timestamp, creationTimestamp, ...tab }) => tab);
  },
  (tabs) => JSON.stringify(tabs.map(({ timestamp, creationTimestamp, ...tab }) => tab))
);

const stableTabsSelector = selector({
  key: 'stable-tabs',
  get: ({ get }) => {
    const tabs = get(openTabsState);
    const stableTabs = memoizedStableTabs(tabs);
    return stableTabs;
  },
});

/**
 * Given an array of tabs, returns a partioned object using the
 * tab type, or a concatenation of type + id to keep the object single level.
 *
 * First sorts the array so pushing from an iterated sorted list will
 * remain sorted.
 */
const partitionTabs = (tabs: PageType[]) => {
  const sorted = [...tabs];

  sorted.sort((a, b) => a.createTimestamp - b.createTimestamp);

  return sorted.reduce(
    (acc, tab) => {
      const key =
        tab.type === PAGE_TYPES.VIEWER && tab.windowId != null
          ? `${tab.type}-${tab.windowId}`
          : tab.type;

      if (acc[key] == null) {
        acc[key] = [];
      }

      acc[key].push(tab);

      return acc;
    },
    ({}: { [s: string]: PageType[] })
  );
};

type Tab = {
  type: $Values<typeof PAGE_TYPES>,
  windowId: ?string,
  url: string,
  worklistIds: Array<string>,
  // TODO enhance with priors list
};

export const useOpenTabs = (): Array<Tab> => {
  return useRecoilValue(stableTabsSelector);
};

/**
 * Handles add and remove tabs through registration process, and page load
 *
 */
export const tabUpdater =
  (
    currentTab: PageType | string,
    navigate?: $Call<typeof useNavigate>,
    shouldRedirectDupeTabs?: boolean
  ): ((tabs: PageType[]) => PageType[]) =>
  (tabs: PageType[]): PageType[] => {
    // Remove/Unregister the tab if we only have an id
    if (typeof currentTab === 'string') {
      return [...tabs.filter((tab) => tab.id !== currentTab)];
    }

    // Add a new tab to the list, after removing its original location
    const filtered = [...tabs.filter((tab) => tab.id !== currentTab.id), currentTab];

    const toRemove = [];
    if (shouldRedirectDupeTabs === true) {
      const grouped = partitionTabs(filtered);

      Object.values(grouped).forEach((tabGroup) => {
        if (tabGroup.length > 1 && UNIQUE_PAGES.includes(tabGroup[0].type)) {
          tabGroup.slice(1).forEach((tab) => {
            toRemove.push(tab.id);

            // Compare to current active window for navigation
            if (tab.id === id && navigate != null) {
              navigate(DUPLICATE_NAVIGATION_PAGE, { replace: true });
            }
          });
        }
      });
    }

    return [...filtered.filter((tab) => !toRemove.includes(tab.id))];
  };

export const useTrackOpenTabs = (): void => {
  const setTabs = useSetRecoilState(openTabsState);
  const [shouldRedirectDupeTabs] = useFeatureFlagEnabled(FF.DUPE_TAB_REDIRECT);

  const navigate = useNavigate();

  useEffect(() => {
    ping();
    const interval = setInterval(register, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const location = useLocation();
  useEffect(() => {
    setTabs(tabUpdater(getPage(), navigate, shouldRedirectDupeTabs));
    register();
  }, [setTabs, location.pathname, navigate, shouldRedirectDupeTabs]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTabs((tabs) => {
        const updatedTabs = tabs.filter((tab) => {
          if (tab.id === id) {
            return true;
          }
          if (tab.timestamp != null && tab.timestamp < Date.now() - POLL_INTERVAL * 1.5) {
            return false;
          }
          return true;
        });

        if (updatedTabs.length !== tabs.length) {
          updatedTabs.sort((a, b) => a.createTimestamp - b.createTimestamp);

          return updatedTabs;
        }
        return tabs;
      });
    }, POLL_INTERVAL * 1.5);

    return () => clearInterval(interval);
  }, [setTabs]);

  useEffect(() => {
    const setTabHandler = (event: Message) => {
      switch (event.type) {
        case 'register': {
          setTabs(tabUpdater(event.payload, navigate, shouldRedirectDupeTabs));
          break;
        }
        case 'unregister': {
          setTabs(tabUpdater(event.payload.id, navigate, shouldRedirectDupeTabs));
          break;
        }
        case 'ping': {
          register();
          break;
        }
        default:
          throw new Error(`Unknown type ${event.type}`);
      }
    };
    bc.addEventListener('message', setTabHandler);
    return () => bc.removeEventListener('message', setTabHandler);
  }, [setTabs, navigate, shouldRedirectDupeTabs]);
};

export const crossTabBroadcastChannel: BroadcastChannel<
  | {
      type: 'request' | 'response',
      url: string,
      id?: string,
    }
  | {
      type: 'setSize',
      tabId: string,
      width?: number,
      height?: number,
      top?: number,
      left?: number,
    },
> = new BroadcastChannel('cross-tab-controls');

const TIME_TO_LOAD =
  window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;

// Highest value between 3s and the (time it took to load the current page + 1s)
const CHECK_TIMEOUT = Math.max(3000, TIME_TO_LOAD + 1000);

/**
 * The below behemoth is required to detect if a popup has been blocked
 * by an ad blocker so that we can inform the user about it.
 *
 * The system is actually quite simple, every page listens to a specific
 * broadcast channel event, when the event is received, the page will
 * check if the event is looking for that specific page, and if it is,
 * it will send a message back to the page that requested the check.
 */
export const checkTabPresence = (url: string): Promise<?string> => {
  return new Promise(async (resolve) => {
    let aborted = false;

    // This will fire after 1 second if no `response` event is received
    const timeout = setTimeout(() => {
      aborted = true;
      resolve(null);
    }, CHECK_TIMEOUT);

    // If a `response` event is received and the URL matches, we can
    // assume that the tab is open and we can resolve the promise
    // with the tab ID
    const callback = async (
      event:
        | { id?: string, type: 'request' | 'response', url: string }
        | {
            height?: number,
            left?: number,
            tabId: string,
            top?: number,
            type: 'setSize',
            width?: number,
          }
    ) => {
      if (event.type === 'response' && event.url === url) {
        aborted = true;

        // If we receive a response we cancel the above timer and resolve the promise positively
        clearTimeout(timeout);
        resolve(event.id);
      }
    };
    crossTabBroadcastChannel.addEventListener('message', callback);

    // We send a request to the broadcast channel, this will trigger
    // the callback above if a tab is open with the same URL
    // We retry every 100ms until we either receive a response or
    // the timeout is reached
    while (aborted === false) {
      crossTabBroadcastChannel.postMessage({ type: 'request', url, id });
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    // We remove the callback from the broadcast channel
    crossTabBroadcastChannel.removeEventListener('message', callback);
  });
};

/**
 * The following event listener is used to make the `checkTabPresence`
 * and `setTabSize` work.
 */
crossTabBroadcastChannel.addEventListener('message', (event) => {
  switch (event.type) {
    case 'request': {
      if (event.url === window.location.pathname) {
        crossTabBroadcastChannel.postMessage({
          type: 'response',
          url: event.url,
          id,
        });
      }
      break;
    }
    case 'setSize': {
      if (event.tabId === id) {
        if (event.width != null && event.height != null) {
          // This is the height of the tabs bar + address bar + bookmarks bar
          const browserInterfaceHeight = window.outerHeight - window.innerHeight;
          window.resizeTo(event.width, event.height + browserInterfaceHeight);
        }
        if (event.top != null && event.left != null) {
          window.moveTo(event.left, event.top);
        }
      }
      break;
    }
    default:
  }
});
