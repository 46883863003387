/* THIS FILE IS AUTO-GENERATED. DO NOT EDIT. */

// @flow

export const FF: {
  ANATOMIC_NAVIGATOR: 'ANATOMIC_NAVIGATOR',
  DEFAULT_WORKLIST_DATE_RANGE_DAYS_OFFSET: 'DEFAULT_WORKLIST_DATE_RANGE_DAYS_OFFSET',
  DUPE_TAB_REDIRECT: 'DUPE_TAB_REDIRECT',
  EDIT_CASE_PRIORITY: 'EDIT_CASE_PRIORITY',
  ENABLE_REPORTER_SUBMISSION: 'ENABLE_REPORTER_SUBMISSION',
  GSPS_ANNOTATIONS: 'GSPS_ANNOTATIONS',
  HANGING_PROTOCOL_DESERIALIZATION: 'HANGING_PROTOCOL_DESERIALIZATION',
  HANGING_PROTOCOL_VERSION: 'HANGING_PROTOCOL_VERSION',
  IMAGE_BOOKMARK: 'IMAGE_BOOKMARK',
  LAYOUT_CONFIG: 'LAYOUT_CONFIG',
  LUNG_WORKFLOW: 'LUNG_WORKFLOW',
  MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS: 'MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS',
  PREFETCH_CLAIMED_WORKLIST_ITEMS: 'PREFETCH_CLAIMED_WORKLIST_ITEMS',
  PREFETCH_HOVERED_WORKLIST_ITEM: 'PREFETCH_HOVERED_WORKLIST_ITEM',
  PREFETCH_READ_CASE_ITEM: 'PREFETCH_READ_CASE_ITEM',
  PREFETCH_TOP_WORKLIST_ITEMS: 'PREFETCH_TOP_WORKLIST_ITEMS',
  PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING: 'PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING',
  PREFETCH_TOP_WORKLIST_ITEMS_LARGE: 'PREFETCH_TOP_WORKLIST_ITEMS_LARGE',
  REPORTER_AI_MODE: 'REPORTER_AI_MODE',
  REPORTER_ANATOMIC_NAVIGATOR: 'REPORTER_ANATOMIC_NAVIGATOR',
  REPORTER_ASR_PLEX: 'REPORTER_ASR_PLEX',
  ACCURATE_TEXT_PLACEMENT: 'ACCURATE_TEXT_PLACEMENT',
  REPORTER_AUTO_IMPRESSIONS: 'REPORTER_AUTO_IMPRESSIONS',
  REPORTER_AUTO_FILL_COMPARISON: 'REPORTER_AUTO_FILL_COMPARISON',
  REPORTER_CLAIM_LOCKED_CASE: 'REPORTER_CLAIM_LOCKED_CASE',
  REPORTER_EXPERIMENTAL_OPTIONS: 'REPORTER_EXPERIMENTAL_OPTIONS',
  REPORTER_FOCUS_MODE: 'REPORTER_FOCUS_MODE',
  REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS: 'REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS',
  REPORTER_RECORDING_STOP_DELAY: 'REPORTER_RECORDING_STOP_DELAY',
  REPORT_CONTENT_UNMASKED: 'REPORT_CONTENT_UNMASKED',
  REPORT_OUTLINE: 'REPORT_OUTLINE',
  REQUEST_NODULE_MSI: 'REQUEST_NODULE_MSI',
  SHOW_MOUSE_SENSITIVITY_SETTINGS: 'SHOW_MOUSE_SENSITIVITY_SETTINGS',
  SLATE_OPS_LOG: 'SLATE_OPS_LOG',
  URT_ENABLED: 'URT_ENABLED',
  URT_MED_CHECK_ENABLED: 'URT_MED_CHECK_ENABLED',
  USE_INVERTED_IMAGE_DETECTION: 'USE_INVERTED_IMAGE_DETECTION',
  VIEWER_DATA_REFETCH: 'VIEWER_DATA_REFETCH',
  VIEWER_TRANSFER_TYPE: 'VIEWER_TRANSFER_TYPE',
  VIEWER_TRANSFER_PROTOCOL: 'VIEWER_TRANSFER_PROTOCOL',
  WORKLIST_COLUMN_FILTERS: 'WORKLIST_COLUMN_FILTERS',
  WORKLIST_CUSTOM_STATUS: 'WORKLIST_CUSTOM_STATUS',
  WORKLIST_IMPROVED_FILTERS: 'WORKLIST_IMPROVED_FILTERS',
  WORKLIST_PAGINATION: 'WORKLIST_PAGINATION',
  refresh_viewer_on_new_case: 'refresh_viewer_on_new_case',
  VIEWPORT_TYPE_THREE_D_DRE_ENABLED: 'VIEWPORT_TYPE_THREE_D_DRE_ENABLED',
  SSC_RELEVANCY_NO_BODY: 'SSC_RELEVANCY_NO_BODY',
  SSC_RELEVANCY_WITH_BODY: 'SSC_RELEVANCY_WITH_BODY',
  IMAGE_REGISTRATION_ALIGNMENT: 'IMAGE_REGISTRATION_ALIGNMENT',
  IMAGE_REGISTRATION_MODEL_VERSION: 'IMAGE_REGISTRATION_MODEL_VERSION',
  PET_CT_OVERLAY_ENABLED: 'PET_CT_OVERLAY_ENABLED',
  REPORTER_EXAM_COUNT: 'REPORTER_EXAM_COUNT',
  PATIENT_JACKET_PRIOR_SUMMARY: 'PATIENT_JACKET_PRIOR_SUMMARY',
} = Object.freeze({
  ANATOMIC_NAVIGATOR: 'ANATOMIC_NAVIGATOR',
  DEFAULT_WORKLIST_DATE_RANGE_DAYS_OFFSET: 'DEFAULT_WORKLIST_DATE_RANGE_DAYS_OFFSET',
  DUPE_TAB_REDIRECT: 'DUPE_TAB_REDIRECT',
  EDIT_CASE_PRIORITY: 'EDIT_CASE_PRIORITY',
  ENABLE_REPORTER_SUBMISSION: 'ENABLE_REPORTER_SUBMISSION',
  GSPS_ANNOTATIONS: 'GSPS_ANNOTATIONS',
  HANGING_PROTOCOL_DESERIALIZATION: 'HANGING_PROTOCOL_DESERIALIZATION',
  HANGING_PROTOCOL_VERSION: 'HANGING_PROTOCOL_VERSION',
  IMAGE_BOOKMARK: 'IMAGE_BOOKMARK',
  LAYOUT_CONFIG: 'LAYOUT_CONFIG',
  LUNG_WORKFLOW: 'LUNG_WORKFLOW',
  MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS: 'MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS',
  PREFETCH_CLAIMED_WORKLIST_ITEMS: 'PREFETCH_CLAIMED_WORKLIST_ITEMS',
  PREFETCH_HOVERED_WORKLIST_ITEM: 'PREFETCH_HOVERED_WORKLIST_ITEM',
  PREFETCH_READ_CASE_ITEM: 'PREFETCH_READ_CASE_ITEM',
  PREFETCH_TOP_WORKLIST_ITEMS: 'PREFETCH_TOP_WORKLIST_ITEMS',
  PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING: 'PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING',
  PREFETCH_TOP_WORKLIST_ITEMS_LARGE: 'PREFETCH_TOP_WORKLIST_ITEMS_LARGE',
  REPORTER_AI_MODE: 'REPORTER_AI_MODE',
  REPORTER_ANATOMIC_NAVIGATOR: 'REPORTER_ANATOMIC_NAVIGATOR',
  REPORTER_ASR_PLEX: 'REPORTER_ASR_PLEX',
  ACCURATE_TEXT_PLACEMENT: 'ACCURATE_TEXT_PLACEMENT',
  REPORTER_AUTO_IMPRESSIONS: 'REPORTER_AUTO_IMPRESSIONS',
  REPORTER_AUTO_FILL_COMPARISON: 'REPORTER_AUTO_FILL_COMPARISON',
  REPORTER_CLAIM_LOCKED_CASE: 'REPORTER_CLAIM_LOCKED_CASE',
  REPORTER_EXPERIMENTAL_OPTIONS: 'REPORTER_EXPERIMENTAL_OPTIONS',
  REPORTER_FOCUS_MODE: 'REPORTER_FOCUS_MODE',
  REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS: 'REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS',
  REPORTER_RECORDING_STOP_DELAY: 'REPORTER_RECORDING_STOP_DELAY',
  REPORT_CONTENT_UNMASKED: 'REPORT_CONTENT_UNMASKED',
  REPORT_OUTLINE: 'REPORT_OUTLINE',
  REQUEST_NODULE_MSI: 'REQUEST_NODULE_MSI',
  SHOW_MOUSE_SENSITIVITY_SETTINGS: 'SHOW_MOUSE_SENSITIVITY_SETTINGS',
  SLATE_OPS_LOG: 'SLATE_OPS_LOG',
  URT_ENABLED: 'URT_ENABLED',
  URT_MED_CHECK_ENABLED: 'URT_MED_CHECK_ENABLED',
  USE_INVERTED_IMAGE_DETECTION: 'USE_INVERTED_IMAGE_DETECTION',
  VIEWER_DATA_REFETCH: 'VIEWER_DATA_REFETCH',
  VIEWER_TRANSFER_TYPE: 'VIEWER_TRANSFER_TYPE',
  VIEWER_TRANSFER_PROTOCOL: 'VIEWER_TRANSFER_PROTOCOL',
  WORKLIST_COLUMN_FILTERS: 'WORKLIST_COLUMN_FILTERS',
  WORKLIST_CUSTOM_STATUS: 'WORKLIST_CUSTOM_STATUS',
  WORKLIST_IMPROVED_FILTERS: 'WORKLIST_IMPROVED_FILTERS',
  WORKLIST_PAGINATION: 'WORKLIST_PAGINATION',
  refresh_viewer_on_new_case: 'refresh_viewer_on_new_case',
  VIEWPORT_TYPE_THREE_D_DRE_ENABLED: 'VIEWPORT_TYPE_THREE_D_DRE_ENABLED',
  SSC_RELEVANCY_NO_BODY: 'SSC_RELEVANCY_NO_BODY',
  SSC_RELEVANCY_WITH_BODY: 'SSC_RELEVANCY_WITH_BODY',
  IMAGE_REGISTRATION_ALIGNMENT: 'IMAGE_REGISTRATION_ALIGNMENT',
  IMAGE_REGISTRATION_MODEL_VERSION: 'IMAGE_REGISTRATION_MODEL_VERSION',
  PET_CT_OVERLAY_ENABLED: 'PET_CT_OVERLAY_ENABLED',
  REPORTER_EXAM_COUNT: 'REPORTER_EXAM_COUNT',
  PATIENT_JACKET_PRIOR_SUMMARY: 'PATIENT_JACKET_PRIOR_SUMMARY',
});
export const FFDefaults: {
  ANATOMIC_NAVIGATOR: string,
  DEFAULT_WORKLIST_DATE_RANGE_DAYS_OFFSET: string,
  DUPE_TAB_REDIRECT: string,
  EDIT_CASE_PRIORITY: string,
  ENABLE_REPORTER_SUBMISSION: string,
  GSPS_ANNOTATIONS: string,
  HANGING_PROTOCOL_DESERIALIZATION: string,
  HANGING_PROTOCOL_VERSION: string,
  IMAGE_BOOKMARK: string,
  LAYOUT_CONFIG: string,
  LUNG_WORKFLOW: string,
  MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS: string,
  PREFETCH_CLAIMED_WORKLIST_ITEMS: string,
  PREFETCH_HOVERED_WORKLIST_ITEM: string,
  PREFETCH_READ_CASE_ITEM: string,
  PREFETCH_TOP_WORKLIST_ITEMS: string,
  PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING: string,
  PREFETCH_TOP_WORKLIST_ITEMS_LARGE: string,
  REPORTER_AI_MODE: string,
  REPORTER_ANATOMIC_NAVIGATOR: string,
  REPORTER_ASR_PLEX: string,
  ACCURATE_TEXT_PLACEMENT: string,
  REPORTER_AUTO_IMPRESSIONS: string,
  REPORTER_AUTO_FILL_COMPARISON: string,
  REPORTER_CLAIM_LOCKED_CASE: string,
  REPORTER_EXPERIMENTAL_OPTIONS: string,
  REPORTER_FOCUS_MODE: string,
  REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS: string,
  REPORTER_RECORDING_STOP_DELAY: string,
  REPORT_CONTENT_UNMASKED: string,
  REPORT_OUTLINE: string,
  REQUEST_NODULE_MSI: string,
  SHOW_MOUSE_SENSITIVITY_SETTINGS: string,
  SLATE_OPS_LOG: string,
  URT_ENABLED: string,
  URT_MED_CHECK_ENABLED: string,
  USE_INVERTED_IMAGE_DETECTION: string,
  VIEWER_DATA_REFETCH: string,
  VIEWER_TRANSFER_TYPE: string,
  VIEWER_TRANSFER_PROTOCOL: string,
  WORKLIST_COLUMN_FILTERS: string,
  WORKLIST_CUSTOM_STATUS: string,
  WORKLIST_IMPROVED_FILTERS: string,
  WORKLIST_PAGINATION: string,
  refresh_viewer_on_new_case: string,
  VIEWPORT_TYPE_THREE_D_DRE_ENABLED: string,
  SSC_RELEVANCY_NO_BODY: string,
  SSC_RELEVANCY_WITH_BODY: string,
  IMAGE_REGISTRATION_ALIGNMENT: string,
  IMAGE_REGISTRATION_MODEL_VERSION: string,
  PET_CT_OVERLAY_ENABLED: string,
  REPORTER_EXAM_COUNT: string,
  PATIENT_JACKET_PRIOR_SUMMARY: string,
} = Object.freeze({
  ANATOMIC_NAVIGATOR: 'off',
  DEFAULT_WORKLIST_DATE_RANGE_DAYS_OFFSET: '0',
  DUPE_TAB_REDIRECT: 'off',
  EDIT_CASE_PRIORITY: 'off',
  ENABLE_REPORTER_SUBMISSION: 'on',
  GSPS_ANNOTATIONS: 'off',
  HANGING_PROTOCOL_DESERIALIZATION: 'legacy',
  HANGING_PROTOCOL_VERSION: 'V1',
  IMAGE_BOOKMARK: 'on',
  LAYOUT_CONFIG: '8,3',
  LUNG_WORKFLOW: 'off',
  MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS: '2',
  PREFETCH_CLAIMED_WORKLIST_ITEMS: 'off',
  PREFETCH_HOVERED_WORKLIST_ITEM: 'off',
  PREFETCH_READ_CASE_ITEM: 'off',
  PREFETCH_TOP_WORKLIST_ITEMS: 'on',
  PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING: '5',
  PREFETCH_TOP_WORKLIST_ITEMS_LARGE: '20',
  REPORTER_AI_MODE: 'off',
  REPORTER_ANATOMIC_NAVIGATOR: 'off',
  REPORTER_ASR_PLEX: 'off',
  ACCURATE_TEXT_PLACEMENT: 'off',
  REPORTER_AUTO_IMPRESSIONS: 'off',
  REPORTER_AUTO_FILL_COMPARISON: 'off',
  REPORTER_CLAIM_LOCKED_CASE: 'off',
  REPORTER_EXPERIMENTAL_OPTIONS: 'off',
  REPORTER_FOCUS_MODE: 'off',
  REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS: 'off',
  REPORTER_RECORDING_STOP_DELAY: '250',
  REPORT_CONTENT_UNMASKED: 'off',
  REPORT_OUTLINE: 'off',
  REQUEST_NODULE_MSI: 'off',
  SHOW_MOUSE_SENSITIVITY_SETTINGS: 'off',
  SLATE_OPS_LOG: 'on',
  URT_ENABLED: 'off',
  URT_MED_CHECK_ENABLED: 'off',
  USE_INVERTED_IMAGE_DETECTION: 'on',
  VIEWER_DATA_REFETCH: 'off',
  VIEWER_TRANSFER_TYPE: 'url-pixels',
  VIEWER_TRANSFER_PROTOCOL: 'http',
  WORKLIST_COLUMN_FILTERS: 'off',
  WORKLIST_CUSTOM_STATUS: 'off',
  WORKLIST_IMPROVED_FILTERS: 'off',
  WORKLIST_PAGINATION: 'off',
  refresh_viewer_on_new_case: 'off',
  VIEWPORT_TYPE_THREE_D_DRE_ENABLED: 'on',
  SSC_RELEVANCY_NO_BODY: 'on',
  SSC_RELEVANCY_WITH_BODY: 'off',
  IMAGE_REGISTRATION_ALIGNMENT: 'off',
  IMAGE_REGISTRATION_MODEL_VERSION: 'any',
  PET_CT_OVERLAY_ENABLED: 'off',
  REPORTER_EXAM_COUNT: 'off',
  PATIENT_JACKET_PRIOR_SUMMARY: 'on',
});
export type FeatureFlag = $Values<typeof FF>;
